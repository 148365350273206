import { render, staticRenderFns } from "./latformInfo.vue?vue&type=template&id=468bf2fa&scoped=true&"
import script from "./latformInfo.vue?vue&type=script&lang=js&"
export * from "./latformInfo.vue?vue&type=script&lang=js&"
import style0 from "./latformInfo.vue?vue&type=style&index=0&id=468bf2fa&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "468bf2fa",
  null
  
)

export default component.exports