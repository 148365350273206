<template>
  <div>
    <frame :top1="top1" :top2="top2">
      <!-- 头部添加优惠券一行 -->
      <div class="head">
        <div class="left">
          <b class="left_tit">平台信息</b>
        </div>
        <div class="right">
          <el-button class="refresh" @click="refresh()" icon="el-icon-refresh"
            >返回</el-button
          >
        </div>
      </div>
      <!-- 添加广告文字一行 -->
      <div class="screen">
        <div class="screen_left">
          <i class="el-icon-search"></i>
          平台信息设置
        </div>
      </div>

      <!-- 添加表单主题部分 -->
      <div class="center">
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          label-width="100px"
          :rules="ruleValidate"
        >
          <el-form-item label="适用商户:" v-show="merchantShow">
            <el-select
              v-model="ruleForm.business_id"
              placeholder="请选择商户"
              @change="changeMerchant(ruleForm.business_id)"
              style="width: 300px"
            >
              <el-option
                v-for="item in ruleForm.businessList"
                :key="item.business_id"
                :label="item.business_name"
                :value="item.business_id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="适用门店:" v-show="storeShow">
            <el-select
              v-model="ruleForm.storeid"
              placeholder="请选择门店"
              style="width: 300px"
            >
              <el-option
                v-for="item in ruleForm.storeList"
                :key="item.s_name"
                :label="item.s_name"
                :value="item.storeid"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="平台名称：" prop="platform_name">
            <el-input
              v-model="ruleForm.platform_name"
              style="width: 300px"
            ></el-input>
          </el-form-item>

          <el-form-item label="平台标题：" prop="platform_title">
            <el-input
              v-model="ruleForm.platform_title"
              style="width: 300px"
            ></el-input>
          </el-form-item>

          <el-form-item label="平台描述：" prop="platform_desc">
            <el-input
              v-model="ruleForm.platform_desc"
              style="width: 300px"
            ></el-input>
          </el-form-item>

          <el-form-item label="关键词：" prop="key_word">
            <el-input
              v-model="ruleForm.key_word"
              style="width: 300px"
            ></el-input>
          </el-form-item>

          <el-form-item label="平台LOGO：">
            <!-- <el-button>选择上传文件</el-button> -->
            <!-- <div style="border:1px solid red"> -->
            <el-upload
              class="avatar-uploader"
              :action="ruleForm.uploadingUrl"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img
                v-if="ruleForm.imageUrl"
                :src="ruleForm.imageUrl"
                class="avatar"
              />
              <i
                v-else
                class="el-icon-plus avatar-uploader-icon"
                style="border: 1px solid red"
              ></i>
              <div slot="tip" class="el-upload__tip">
                只能上传jpg/png文件，且不超过500kb，建议图片大小350*200px
              </div>
              <!-- </div> -->
            </el-upload>
          </el-form-item>

          <el-form-item label="客服热线：" prop="hot_line">
            <el-input
              v-model.number="ruleForm.hot_line"
              style="width: 300px"
            ></el-input>
          </el-form-item>

          <el-form-item label="客服邮箱：" prop="service_email">
            <el-input
              v-model="ruleForm.service_email"
              style="width: 300px"
            ></el-input>
          </el-form-item>

          <el-form-item label="接口域名：" prop="api_link">
            <el-input
              v-model="ruleForm.api_link"
              style="width: 300px"
            ></el-input>
          </el-form-item>

          <el-form-item label="排序：" prop="sort">
            <el-input
              type="textarea"
              v-model="ruleForm.sort"
              style="width: 300px"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-button
              @click="submitForm('ruleForm')"
              type="primary"
              style="width: 120px"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </frame>
  </div>
</template>

<script>
import frame from "../../public/Frame.vue";
export default {
  data() {
    return {
      top1: "11-1",
      top2: ["11"],
      merchantShow: true, //
      storeShow: true, //门店的显示隐藏

      ruleForm: {
        businessList: [{}], //商户列表
        business_id: "", //选中的商户ID
        storeList: [{}], //门店列表
        storeid: "", //选中的门店ID
        uploadingUrl: "", //图片上传路径
        platform_name: "", //		平台名称
        platform_title: "", //平台标题
        platform_desc: "", //平台描述
        key_word: "", //		关键词
        platform_logo: "", //	平台LOGO
        hot_line: "", //客服热线
        service_email: "", //	客服邮箱
        sort: "", //排序
        api_link: "", //接口域名
        imageUrl: "",
      },
      //表单验证数据
      ruleValidate: {
        platform_name: [
          {
            required: true,
            message: "请输入平台名称", //账号
            trigger: "blur",
          },
        ],
        platform_title: [
          {
            required: true,
            message: "请输入店铺平台标题", //店铺
            trigger: "blur",
          },
        ],
        platform_desc: [
          {
            required: true,
            message: "请输入平台描述", //地址
            trigger: "blur",
          },
        ],
        key_word: [
          {
            required: true,
            message: "请输入关键词", //手机号
            trigger: "blur",
          },
        ],
        hot_line: [
          { required: true, message: "客服热线不能为空" },
          { type: "number", message: "客服热线必须为数字值" },
        ],
        service_email: [
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    submitForm(formName) {
      console.log(formName);
      console.log(this.$refs[formName]);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
          console.log(
            "提交的表单数据",
            "平台名称",
            this.ruleForm.platform_name,

            "平台标题",
            this.ruleForm.platform_title,

            "平台描述",
            this.ruleForm.platform_desc,

            "关键词",
            this.ruleForm.key_word,

            "平台LOGO",
            this.ruleForm.platform_logo,

            "客服热线",
            this.ruleForm.hot_line,

            "客服邮箱",
            this.ruleForm.service_email,

            "排序",
            this.ruleForm.sort,

            "接口域名",
            this.ruleForm.api_link,

            this.$storage.getLocal("type")
          );
          let type = this.$storage.getLocal("type");
          //广告添加接口
          this.$request
            .setPlatformInfo({
              token: this.$storage.getLocal("token"), //登陆进来的tooken
              admin_type: this.$storage.getLocal("type"), //身份
              // admin_type: 1, //身份
              platform_id: "",
              platform_name: this.ruleForm.platform_name, //平台名称
              platform_title: this.ruleForm.platform_title, //平台标题
              platform_desc: this.ruleForm.platform_desc, //	平台描述
              key_word: this.ruleForm.key_word, //	关键词
              platform_logo: this.ruleForm.platform_logo, //平台LOGO
              hot_line: this.ruleForm.hot_line, //	客服热线
              service_email: this.ruleForm.service_email, //客服邮箱
              sort: this.ruleForm.sort, //	排序
              api_link: this.ruleForm.api_link, //	接口域名
              store_id: this.ruleForm.storeid, //门店ID
            })
            .then((res) => {
              console.log(res);
              if (res.code == 0) {
                this.$router.push({ path: "advertisingList" });
              } else {
                alert("请求失败");
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //刷新页面按钮
    refresh() {
      location.reload();
    },
    //选中商户查询门店
    changeMerchant(business_id) {
      console.log(business_id);
      this.$request
        .businessStoreList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          business_id: business_id,
        })
        .then((res) => {
          console.log(res);
          this.ruleForm.storeList = res.data;
        });
    },

    //图片上传操作
    handleReset(name) {
      this.$refs[name].resetFields();
      console.log(this.ruleForm.imageUrl);
      this.ruleForm.mageUrl = "";
    },

    handleAvatarSuccess(res, file) {
      this.ruleForm.imageUrl = URL.createObjectURL(file.raw);
      this.ruleForm.platform_logo = res.data.src;
      console.log(
        456456456,
        this.ruleForm.imageUrl,
        this.ruleForm.platform_logo
      );
    },

    beforeAvatarUpload(file) {
      console.log(789789789);
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.ruleForm.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.ruleForm.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },

    //表单提交
    onSubmit() {},
  },

  created() {
    // 请求商户列表
    this.$request.businessList({}).then((res) => {
      console.log(res);
      this.ruleForm.businessList = res.data;
    });
    let admin_type = this.$storage.getLocal("type");
    if (admin_type == 2 || admin_type == 3) {
      this.merchantShow = false;
      this.$request
        .businessStoreList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          // business_id: business_id,
        })
        .then((res) => {
          console.log("帅气的我",res);
          this.ruleForm.storeList = res.data;
        });
    }
    if (admin_type == 1 || admin_type == 3) {
      this.storeShow = false;
    }
    this.ruleForm.uploadingUrl = this.$request.imgUrl() + "PHPExcel/fileUpload";

    //请求广告位置接口
    this.$request.advertPosition({}).then((res) => {
      console.log(res);

      if (res.code == 0) {
        this.ruleForm.advert_position_list = res.data;
      } else {
        alert("广告位置接口没有请求到");
      }
    });
  },
  components: {
    frame,
  },
};
</script>

<style  scoped>
.head {
  width: 100%;
  height: 45px;
  line-height: 45px;
  background-color: rgb(243, 243, 243);
}
.left,
.right {
  display: inline;
}
.left {
  float: left;
  margin-left: 210px;
}
.left_tit {
  border-left: 5px solid red;
  font-size: 22px;
  padding: 5px;
}
.refresh {
  float: right;
  margin-right: 10%;
  margin-top: 2px;
}
.screen {
  margin-top: 15px;
  width: 85%;
  margin-left: 200px;
  height: 40px;
  height: 40px;
  background-color: rgb(243, 243, 243);
}

.screen_left {
  float: left;
  margin-left: 50px;
  margin-top: 10px;
}
.center {
  width: 40%;
  margin-left: 32%;
  margin-top: 50px;
  /* height: 500px; */
  /* border: 1px solid red; */
}
/* 上传图片的样式 */
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>